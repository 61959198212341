import * as React from 'react'
import { styled } from '@styles'
import { graphql } from 'gatsby'
import { Page, Image, Section } from '@common/components'
import { SEO } from '../common/components/seo'
import { Header, Footer } from '../components'

export const query = graphql`
  query {
    image: file(relativePath: { eq: "backgrounds/404.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const StyledImage = styled(Image)`
  margin-top: 2rem;
`

const NotFoundPage = ({ data }) => {
  return (
    <Page
      seoComponent={<SEO title="404: Not found" />}
      headerComponent={<Header />}
    >
      <Section>
        <StyledImage width="100%" image={data.image.childImageSharp.fluid} />
      </Section>
      <Footer enableTopBackground={false} />
    </Page>
  )
}

export default NotFoundPage
